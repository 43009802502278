<template>
    <section>
        <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">MY STORES</div>
            </div>
        </div>

        <!-- <div class="columns">
            <div class="column is-4 has-text-left">
                <date-range-selector
                    :dateRange="salesDateRange"
                    @set-date-range="setDateRange"
                    >
                </date-range-selector>
            </div>
        </div> -->
        
        <div class="panel-block">
            <b-table class="control" 
                :data='stores'
                :paginated="isPaginated"
                :per-page="perPage"
                striped
                paginationPosition="both"
                sortIcon="arrow-up"
                sortIconSize="is-small"
                default-sort=storeName
                defaultSortDirection='asc'
                >

            <template>
                <b-table-column class="is-2 has-text-right" field="StoreId" label="Set Store" sortable sorticon v-slot="props">
                    <b-tooltip position="is-left" label="Set Active Store" dashed >
                        <b-button class="is-primary is-small"  v-on:click="setActiveStore(props.row)" outlined rounded>⛽</b-button>
                    </b-tooltip> 
                </b-table-column>                
                <b-table-column field="storeName" label="Store Name" sortable sorticon searchable  v-slot="props">
                    {{ props.row.storeName }}
                </b-table-column>

                <b-table-column field="addressLine1" label="Address" searchable v-slot="props">
                    {{ props.row.addressLine1 }} 
                </b-table-column>

                <b-table-column field="city" label="City" searchable v-slot="props">
                    {{ props.row.city }} {{ props.row.state}} 
                    <!-- {{ props.row.country }} -->
                </b-table-column>
                <b-table-column field="zipCode" label="Zip Code" searchable v-slot="props">
                  {{ props.row.zipCode }} 
                </b-table-column>
                <b-table-column field="officeNumber" label="Office Number" searchable v-slot="props">
                  {{ props.row.officeNumber }} 
                </b-table-column>
                <!-- <b-table-column field="merchSales" label="Merch Sales" searchable v-slot="props">
                    <span v-if="props.row.storeLoading">
                        <i class="fas fa-spinnter fa-pulse"></i>
                    </span>
                    <span v-else>{{ props.row.totalMerchSales | formatCurrency }} </span>
                </b-table-column>  -->
                <!-- <b-table-column field="cellNumber" label="Cell Number" searchable v-slot="props">
                  {{ getStoreDeviceStatus(props.row) }} 
                </b-table-column>                                           -->
            </template>

            <!-- <template slot="detail" slot-scope="props"> -->
                <!-- <b-table :data="props.row.summaryByDate">
                    <b-table-column field="date" label="Date" v-slot="props">
                        {{ (props.row.length > 0 ? props.row[0].date : "") | formatDate }}
                    </b-table-column> -->
                    <!-- <b-table-column numeric field="merchSales" label="Merch Sales" v-slot="props">
                        {{ totalAmount(props.row, "merchSales") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="fuelSales" label="Fuel Sales" v-slot="props">
                        {{ totalAmount(props.row, "fuelSales") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="fuelVol" label="Fuel Gal." v-slot="props">
                        {{ totalFuelVolume(props.row, "fuelVol") | formatNumber }}
                    </b-table-column>
                    <b-table-column numeric field="cashAmount" label="Cash Sales" v-slot="props">
                        {{ totalAmount(props.row, "cashAmount") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="netLottery" label="Net Lottery" v-slot="props">
                        {{ netLottery(props.row) | formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="cashIncomeExpe" label="Cash Inc/Exp" v-slot="props">
                        {{ totalAmount(props.row, "totalCashIncome") - totalAmount(props.row, "totalCashExpense") | formatCurrency }} 
                    </b-table-column>
                    <b-table-column numeric field="totalSafeDrop" label="Safe Drop" v-slot="props">
                        {{ totalAmount(props.row, "totalSafeDrop") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="cashActualOnhand" label="Actual Cash" v-slot="props">
                        {{ totalAmount(props.row, "cashActualOnhand") | formatCurrency }}
                    </b-table-column>
                    <b-table-column numeric field="cashOverShort" label="Over/Short" v-slot="props">
                        {{ totalAmount(props.row, "cashOverShort") | formatCurrency }}
                    </b-table-column> -->
                <!-- </b-table> -->
            <!-- </template> -->
            
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

            <!-- <template slot="top-left">
                <router-link to="/user/mystoressummary">
                    <b-button class="is-primary">Stores with Sales Summary!</b-button>
                </router-link>
            </template> -->

        </b-table>

        </div>
        </div>
    </section>
</template>

<script>

import UserStore from '../../../../store/UserStore'
import DataLoading from '../../../../components/app/DataLoading'
// import DateRangeSelector from '../../../../components/app/DateRangeSelector'

import axios from 'axios'

export default {

    components: {
        DataLoading,
        // DateRangeSelector
    },

    data() {
        return {
            stores: [],
            salesDateRange: [],
            startDate: new Date(),
            endDate: new Date(),
            isPaginated: true,
            perPage: 20,
            isLoading: true,
            isFullPage: true,
        }
    },

    methods: {
        async fetchStores() {
            
            if (UserStore.getters.getUser) {
                this.isLoading = true
                const url = process.env.VUE_APP_API_ROOT_URL + "users/" +
                            UserStore.getters.getUser.id +
                            "/stores" 

                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.stores = response.data.data
                        this.stores.forEach(store => {
                            store.summaryByDate = []
                            store.totalMerchSales = 0.00
                            store.merchSales = 0.00
                            store.fuelSales = 0.00
                            store.fuelVol = 0.00
                            store.cashAmount = 0.00
                            store.netLottery = 0.00
                            store.totalCashIncome = 0.00
                            store.totalCashExpense = 0.00
                            store.totalSafeDrop = 0.00
                            store.cashActualOnhand = 0.00
                            store.cashOverShort = 0.00
                            store.storeLoading = true
                        })
                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }

        }, 

        async fetchStoreSales() {

            await Promise.all(this.stores.map(async (store) => {
                var url = process.env.VUE_APP_API_ROOT_URL + 'stores/' + store.id +
                     "/sales/shifts/net?size=50"  + 
                     "&fromDate="+this.startDate+
                     "&toDate="+this.endDate
                await axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken,
                            'correlation-id': this.$uuid.v1()
                        },                   
                    })
                    .then((response) => {
                        store.summaryByDate = this.groupArrayByProp(response.data.data,"date")
                        store.totalMerchSales = store.summaryByDate.reduce((accum,item) => accum + item.merchSales, 0)
                        console.log(store)
                    })
                    .catch(err => {
                        console.log("Error",err)
                    }) 

            }, Promise.resolve()))


            this.stores.forEach((store,index) => {


                store.cashActualOnhand = index
            })

        },

        totalAmount(row, field) {
            var total = row.reduce((accum,item) => accum + item[field], 0)
            return total
        },

        setActiveStore(row) {
            UserStore.dispatch('setActiveStoreDetails', row.id)
        },

        getStoreDeviceStatus(row) {
            // console.log(row.id,row.storeDeviceStatus)
            if (row.storeDeviceStatus) {
                return "🟢"
            } else {
                return "🔴"
            }
        },

        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0].toISOString().split('T')[0], dateRange[1].toISOString().split('T')[0]]
        },
    },

    watch: {
        salesDateRange() {
            this.startDate = this.salesDateRange[0].split('T')[0]
            this.endDate = this.salesDateRange[1].split('T')[0]
            this.stores = []
            this.fetchStores()
                .then(() => {
                    this.fetchStoreSales()
                })
        }
        
    },

    mounted() {
        this.fetchStores()
            // .then(() => {
            //     this.isLoading = false
            //     this.fetchStoreSales()
            // })
    }

}
</script>